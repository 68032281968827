<template>
    <div class="s-f-container" id="searchFilters">
        <!-- Search -->
        <form class="searchForm" v-on:submit.prevent="submitSearch">
            <i class="fal fa-search mr-2"></i>
            <input type="text" id="search-input" class="input" v-model="searchText" placeholder="Search" @keyup="submitSearch">
            <!-- TODO: X-button to clear search: https://codepen.io/cuppilekkia/pen/YNzgVQ -->
            <span v-show="searchText" class="removeInput" @click="resetSearch"><i class="fal fa-times"></i></span>
        </form>
        <!-- Filters -->
        <div v-if="filters" id="filters-btn" class="">
            <div class="d-flex mb-3 ">
                <div class="">
                    <button class="jbtn jbtn-white" @click="toggleFilterDialog">
                        <i class="fal fa-filter"></i>&nbsp;Filters
                    </button>
                    <!-- <button class="jbtn jbtn-text jbtn-white" @click="toggleFilterDialog">
                        <i class="fal fa-filter"></i>&nbsp;Filters
                    </button> -->
                </div>
            </div>
            <!-- Filters modal -->
            <b-modal id="filters-modal" ref="filters-modal" size="lg" centered hide-footer hide-header
                v-model="filterDialog">
                <!-- header -->
                <div class="jdialog-top">
                    <div class="close-container">
                        <h3>Filters</h3>
                        <span @click="toggleFilterDialog" class="ml-auto jclose"><i class="fal fa-times "></i></span>
                    </div>
                </div>

                <!-- filters -->
                <div v-if="filters" class="jdialog-main duke-style">
                    <!-- Dates -->
                    <div v-for="filter in dateFilters" :key="filter.field" class="form-group">
                        <h5><i class="fal fa-calendar"></i> {{ filter.title }} </h5>
                        <!-- start -->
                        <span class="jbtn jbtn-dark jbtn-filter jbtn-status mr-2 mb-2">Start <b-form-input type="date" class="input" v-model="startDateValues[filter.field]"
                                :state="validateDateRange(filter.field)" reset-button value-as-date>
                            </b-form-input></span>
                        <!-- end -->
                        <span class="jbtn jbtn-dark jbtn-filter jbtn-status mb-2">End <b-form-input type="date" class="input" v-model="endDateValues[filter.field]"
                                :state="validateDateRange(filter.field)" reset-button value-as-date>
                            </b-form-input></span>
                        <!-- <div class="d-flex mb-3">
                            <span class="jbtn jbtn-dark jbtn-filter">Start
                            <b-form-datepicker class="input" v-model="startDateValues[filter.field]"
                                :state="validateDateRange(filter.field)" reset-button value-as-date>
                            </b-form-datepicker></span>
                            <label>End</label>
                            <b-form-datepicker class="input" v-model="endDateValues[filter.field]"
                                :state="validateDateRange(filter.field)" reset-button value-as-date>
                            </b-form-datepicker>
                        </div> -->
                    </div>
                    <!-- Selections -->
                    <div v-for="filter in selectionFilters" :key="filter.field" class="form-group">
                        <h5> {{ filter.title }}</h5>
                        <b-form-select :options="filter.selection" v-model="selectionValues[filter.field]">
                        </b-form-select>
                        <!-- <select data-live-search="true" :options="filters[filter]" v-model="selectionValues[filter]">
                            <option v-for="{test, index} in filters[filter]" :key="index">{{test}}</option>
                        </select> -->
                    </div>
                    <!-- Multi-Selections -->
                    <div v-for="filter in multiSelectionFilters" :key="filter.field" class="d-flex">
                        <div class="form-group">
                            <h5>{{ filter.title }}</h5>
                                <button v-for="selectionValue in Object.keys(multiSelectionValues[filter.title])"
                                :key=selectionValue
                                class="jbtn jbtn-dark jbtn-filter mr-2 mb-2"
                                v-bind:class="{ 'active': getMultiSelectionValue(filter.title, selectionValue) }"
                                @click="setMultiSelectionValue(filter.title, selectionValue); $forceUpdate()">
                                {{ selectionValue }}
                            </button>
                            <!-- v-bind:class="{ 'btn btn-outline-primary ml-2': getMultiSelectionValue(filter.title, selectionValue), 'jbtn-duke jbtn-sm': !getMultiSelectionValue(filter.title, selectionValue) }" -->
                        </div>
                    </div>
                    <!-- Numbers -->
                    <div v-for="filter in numberRangeFilters" :key="filter.field" class="form-group">
                        <h5><i class="fal fa-calendar"></i> {{ filter.title }} </h5>
                        <span class="jbtn jbtn-dark jbtn-filter jbtn-status mr-2 mb-2">Min <b-form-input type="number" class="input" v-model="minNumberValues[filter.field]"
                                :state="validateNumberRange(filter.field)">
                            </b-form-input></span>
                        <span class="jbtn jbtn-dark jbtn-filter jbtn-status mb-2">Max <b-form-input type="number" class="input" v-model="maxNumberValues[filter.field]"
                                :state="validateNumberRange(filter.field)">
                            </b-form-input></span>
                        <div class="d-flex mb-3">
                            <!--<label> Min</label>
                            <b-form-input type="number" class="input" v-model="minNumberValues[filter.field]"
                                :state="validateNumberRange(filter.field)">
                            </b-form-input>
                            <label> Max</label>
                            <b-form-input type="number" class="input" v-model="maxNumberValues[filter.field]"
                                :state="validateNumberRange(filter.field)">
                            </b-form-input> -->
                        </div>
                    </div>
                    <!-- alert -->
                    <b-alert v-if="false" show variant="danger"><i class="fad fa-exclamation-circle"></i>
                        validationMsg
                    </b-alert>
                </div>

                <!-- dialog bottom -->
                <div class="jdialog-bottom with-cancel mt-4">
                    <!-- <button @click="toggleFilterDialog" class="jbtn jbtn-sm jbtn-red">
                        <i class="fa fa-times"></i> Cancel
                    </button> -->
                    <button @click="resetFilters" class="jbtn jbtn-small jbtn-white">
                        <i class="fa fa-rotate-left"></i> Reset
                    </button>
                    <button class="jbtn jbtn-small jbtn-white" @click="applyFilters">
                        <i class="fa fa-check"></i> Apply
                    </button>
                </div>
                <!-- <template #modal-footer=""></template> -->
            </b-modal>
        </div>
    </div>
</template>

<script>
/**
 * This component provides a searchbar and a filter-dialog to filter a given list of objects.
 * @property {Array} searchList is the array of objects that will be manipulated by the searchbar and filter-dialog
 *      - a backup of the complete list is made befor searching/filtering
 *      - the searchlist is manipulated via reference, based on the backup list
 *      - ! keep this in mind when passing props or updating the original list asynchronously !
 * @property {Array} filters is the array of well-defined types of filters that can be added to the dialog
 *      - ! every filter and value needs to be uniquely identifiable => make sure to use unique names !
 *      - 4 diffreent filter types can be passed as well formated objects (see C.FILTER_TYPE)
 *      - each filter prop object needs to have
 *          1. unique title
 *          2. the field in the objects (of the searchlist) that they need to be filtered by
 *          3. the filter type (from C.FILTER_TYPE)
 *          4. selection filters also need the array of possible values to select from
 *      - how the props should look like for filters to work properly:
 *          - selectionFilterProp: {
 *                  title: "filterTitle",
 *                  field: "objectFieldToBeFiltered",
 *                  type: FILTER_TYPE.SELECTION,
 *                  selection: ["SelectionValue1", "SelectionValue2", ...],
 *              },
 *          - selectionFilterProp: {
 *          	    title: "filterTitle",
 *          	    field: "objectFieldToBeFiltered",
 *          	    type: FILTER_TYPE.MULTI_SELECTION,
 *          	    selection: ["SelectionValue1", "SelectionValue2", ...],
 *              },
 *          - dateFilterProp: {
 *          	    title: "filterTitle",
 *          	    field: "objectFieldToBeFiltered",
 *          	    type: FILTER_TYPE.DATE,
 *              },
 *          - numberRangeFilterProp: {
 *                  title: "filterTitle",
 *                  field: "objectFieldToBeFiltered",
 *                  type: FILTER_TYPE.NUMBER_RANGE,
 *              },
 * @property {String} startSearchText (optional) for pre-searching the list when entering
 * 
 * 
 * TODO: better handling for filter orders, especially for Multiselection items
 *  
 */


/**
 * Selections might only show 'All' since there might be nothing to select from.
 * E.g. if the list is empty and you dont want to let the user filter entries that are not represented in the list
 * TODO: add as a prop for increased flexibility
 */
const SHOW_EMPTY_SELECTION_FILTERS = true;

import C from "@/const";
export default {
    name: 'SearchAndFilters',
    props: {
        searchList: Array,
        filters: Array,
        startingSearchText: String
    },
    data() {
        return {
            filterDialog: false,
            searchText: "",
            listBackup: [],
            selectionValues: {},
            multiSelectionValues: {},
            startDateValues: {},
            endDateValues: {},
            minNumberValues: {},
            maxNumberValues: {},
            initMultiSelectionDone: false,
        };
    },
    mounted() {
        this.initSearch();
        this.initMultiSelection();
        if (this.startingSearchText) {
            this.searchText = this.startingSearchText;
            this.submitSearch();
        }
    },
    computed: {
        selectionFilters() {
            return this.filters.filter(filter =>
                filter.type === C.FILTER_TYPE.SELECTION &&
                (SHOW_EMPTY_SELECTION_FILTERS || (filter.selection && filter.selection.length != 0))
            );
        },
        multiSelectionFilters() {
            if (!this.initMultiSelectionDone) {
                return {};
            }
            return this.filters.filter(filter =>
                filter.type === C.FILTER_TYPE.MULTI_SELECTION &&
                (SHOW_EMPTY_SELECTION_FILTERS || (filter.selection && filter.selection.length != 0))
            );
        },
        dateFilters() {
            return this.filters.filter(filter => filter.type === C.FILTER_TYPE.DATE);
        },
        numberRangeFilters() {
            return this.filters.filter(filter => filter.type === C.FILTER_TYPE.NUMBER_RANGE);
        },

    },
    methods: {
        getMultiSelectionValue(filterTitle, selectionTitle) {
            const selections = this.multiSelectionValues[filterTitle];
            if (!selections)
                return null;
            return selections[selectionTitle];
        },
        setMultiSelectionValue(filterTitle, selectionTitle) {
            if (!this.multiSelectionValues[filterTitle])
                return;

            if (selectionTitle === "All") {
                this.resetMultSelectionFilter(filterTitle);
                return;
            } else
                this.multiSelectionValues[filterTitle].All = false;

            this.multiSelectionValues[filterTitle][selectionTitle] = !this.multiSelectionValues[filterTitle][selectionTitle];
            // check if it was the last one => active "All"
            if (Object.values(this.multiSelectionValues[filterTitle]).every((value) => !value)) {
                this.multiSelectionValues[filterTitle].All = true;
            }
        },
        resetMultSelectionFilter: function (filterTitle) {
            if (!filterTitle || !this.multiSelectionValues[filterTitle])
                return;

            // set "All"-entry to true, rest to false
            Object.keys(this.multiSelectionValues[filterTitle]).forEach((key) => {
                this.multiSelectionValues[filterTitle][key] = (key === "All");
            });
        },

        /**
         * SEARCH
         */

        searchIsReady: function () {
            return ((this.listBackup.length > 0) || !this.searchList);
        },
        initSearch: function () {
            // console.log("INIT SEARCH");
            if (!this.searchList) {
                console.log('SearchAndFilters error: no list yet');
                return;
            }

            // deep copy for the complete 'backup' list
            this.listBackup = [...this.searchList];
            // console.log("LIST READY: " + this.listBackup.length);
        },
        resetSearch: function () {
            if (!this.searchIsReady()) {
                // initSearch was not successfull yet (list from props might not have been populated yet)
                this.initSearch();
            }

            this.searchList.length = 0;
            // TODO: maybe find a more performant way to copy the values while keeping the references at the original array (which comes from the parent component)
            this.listBackup.forEach(element => {
                if (this.isMatchingFilters(element))
                    this.searchList.push(element);
            });
            this.searchText = "";
        },
        submitSearch: function () {
            // because of async issues, inits are not always done after the component is mounted
            // => need to always check first
            if (!this.searchIsReady()) {
                // initSearch was not successfull yet (list from props might not have been populated yet)
                this.initSearch();
            }
            if (!this.listBackup.length && this.searchIsReady()) {
                console.log("Nothing to search");
                return;
            }
            if (!this.initMultiSelectionDone) {
                this.initMultiSelection();
            }

            if (!this.searchText || this.searchText === "") {
                this.resetSearch();
                return;
            }

            this.searchList.splice(0);
            const searchingForNumber = !isNaN(this.searchText);
            for (const item of this.listBackup) {
                if (!this.isMatchingFilters(item)) {
                    console.log("Search: didnt match filters");
                    continue;
                }
                for (const value of Object.values(item)) {
                    // check if we find a matching number (if we are searching for one)
                    if (searchingForNumber && typeof value === 'number') {
                        if ((value + '').includes(this.searchText)) {
                            this.searchList.push(item);
                            break;
                        }
                        continue;
                    }
                    console.log("checking string: ");
                    // check if we find a matching string
                    if (!(typeof value === 'string' || value instanceof String)) {
                        continue;
                    }
                    console.log("found string? : " + value);
                    if (value.toLowerCase().includes(this.searchText.toLowerCase())) {
                        this.searchList.push(item);
                        break;
                    }
                }
            }
        },

        /**
         * Filters
         */

        toggleFilterDialog: function () {
            if (!this.initMultiSelectionDone) {
                this.initMultiSelection();
            }
            if (!this.searchIsReady()) {
                this.initSearch();
            }
            this.filterDialog = !this.filterDialog;
        },
        initMultiSelection: function () {
            if (!this.filters || !this.filters.length)
                return;

            // console.log("initMulit, backuplistlenght: " + this.listBackup.length);
            // mulitselection filters all need to be enabled if we want to show the whole list by default (cannot use computed this.multiSelectionFilters since init needs to run first)
            this.filters.filter(filter => filter.type === C.FILTER_TYPE.MULTI_SELECTION).forEach((filter) => {
                this.multiSelectionValues[filter.title] = {};
                this.multiSelectionValues[filter.title].All = true;
                filter.selection.forEach((selectionItem) => {
                    this.multiSelectionValues[filter.title][selectionItem] = false;
                });
            });
            this.initMultiSelectionDone = true;
        },
        isMatchingFilters: function (item) {
            console.log("checking item for filtermatch");
            // check if filters were set
            if (!this.filters || this.filters.length < 1)
                return true;

            // every filter has to be matched in order to show item in list
            return this.filters.every((filter) => {
                /**
                 * Selection filters
                 */
                if (filter.type == C.FILTER_TYPE.SELECTION) {
                    const filterValue = this.selectionValues[filter.field];
                    // 'ALL' is default (unfiltered)
                    if (!filterValue || (filterValue == "All")) {
                        // this filters has not been set => continue with next one
                        return true;
                    }

                    const filterMatch = (item[filter.field] === filterValue);

                    return filterMatch;

                }
                /**
                 * Multi-Selection filters
                 */
                else if (filter.type == C.FILTER_TYPE.MULTI_SELECTION) {
                    // check if "All" is selected
                    if (this.multiSelectionValues[filter.title].All)
                        return true;

                    // for each multi-selection filter there has to be at least one selected value matching the item being filtered
                    return filter.selection.some((selectionName) => {
                        const selected = this.multiSelectionValues[filter.title][selectionName];
                        return (selected && (item[filter.field] === selectionName));
                    });

                }
                /**
                 * Date filters
                 */
                else if (filter.type == C.FILTER_TYPE.DATE) {
                    const filterValueStart = this.startDateValues[filter.field];
                    const filterValueEnd = this.endDateValues[filter.field];

                    // TODO: check UX if it is better to return true for this case
                    // if (filterValueStart > filterValueEnd)
                    //     return true;
                    const itemDate = C.getDateFromTimestamp(item[filter.field]);
                    if (filterValueStart && (itemDate < filterValueStart))
                        return false;

                    if (filterValueEnd && (itemDate > filterValueEnd))
                        return false;

                    return true;
                }
                /**
                 * Number range filters
                 */
                else if (filter.type == C.FILTER_TYPE.NUMBER_RANGE) {
                    const filterValueMin = this.minNumberValues[filter.field];
                    const filterValueMax = this.maxNumberValues[filter.field];
                    console.log("chceking numberfilter: " + filter.field + " min _ " + filterValueMin + " max_ " + filterValueMax);
                    console.log("user value: " + item[filter.field]);
                    if (this.validateNumberRange(filter.field) === false || (!filterValueMin && !filterValueMax)) {
                        console.log("RANGE NOT VALID");
                        return true;
                    }
                    if (!item[filter.field])
                        return false;
                    const itemValueAsNumber = typeof item[filter.field] == "string" ? parseFloat(item[filter.field]) : item[filter.field];
                    if (filterValueMin && (itemValueAsNumber < parseFloat(filterValueMin))) {
                        console.log("MIN FILTER DOES NOT MATCH: FOR : " + item[filter.field]);
                        return false;
                    }
                    if (filterValueMax && (itemValueAsNumber > parseFloat(filterValueMax))) {
                        console.log("MAX FILTER DOES NOT MATCH: FOR : " + item[filter.field]);
                        return false;
                    }
                    return true;
                }
            });
        },
        applyFilters: function () {
            this.toggleFilterDialog();
            console.log("MULTISELECT READY??? : " + this.initMultiSelectionDone);
            if (!this.listBackup.length && (!this.searchList || !this.searchList.length)) {
                console.log("Nothing to filter");
                return;
            }
            this.resetSearch();

            console.log("checking list with length: " + this.listBackup.length);
            this.searchList.splice(0);
            for (let i = 0; i < this.listBackup.length; i++) {
                // check each filter for matches with 'every()' => as soon as we find a filter that does not match: return false & remove item from list
                const itemMatchesFilters = this.isMatchingFilters(this.listBackup[i]);

                if (!itemMatchesFilters)
                    continue;

                this.searchList.push(this.listBackup[i]);
            }
        },
        resetFilters: function () {
            this.filters.forEach((filter) => {
                if (filter.type === C.FILTER_TYPE.SELECTION) {
                    // set defaultvalue for each possible selection
                    this.selectionValues[filter.field] = filter.selection[0];
                } else if (filter.type === C.FILTER_TYPE.MULTI_SELECTION) {
                    this.initMultiSelection();
                } else if (filter.type === C.FILTER_TYPE.DATE) {
                    this.startDateValues[filter.field] = null;
                    this.endDateValues[filter.field] = null;
                } else if (filter.type === C.FILTER_TYPE.NUMBER_RANGE) {
                    this.minNumberValues[filter.field] = null
                    this.maxNumberValues[filter.field] = null;
                }
            });
            this.applyFilters();
        },
        validateDateRange: function (filterField) {
            const startDate = this.startDateValues[filterField];
            const endDate = this.endDateValues[filterField];
            // valid range => null for uncolored datePicker
            if (!startDate || !endDate || (startDate <= endDate))
                return null;

            // invalid range => false for red colored datePicker
            return false;
        },
        validateNumberRange: function (filterField) {
            const minNumber = this.minNumberValues[filterField];
            const maxNumber = this.maxNumberValues[filterField];

            // if invalid input (i.e. a non-number string) numbers are not set (input is ignored by input component)
            // => need different component if we want to handle non-number inputs differently
            if (!minNumber || !maxNumber)
                return null;

            // if input is valid, it is a string
            if (parseFloat(minNumber) > parseFloat(maxNumber))
                return false;

            return null;
        }

    },
};


</script>

<style scoped></style>
